import { useShopContext } from "@/context/ShopContext";
import { CART } from "@/lib/apollo/fragments";
import { gql, useQuery } from "@apollo/client";
import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { systemDropDownInteractedAnalytics } from "@/utils/analytics";
import { AnimatePresence, motion } from "framer-motion";

import alterLogotype from "../public/alter-logotype.svg";
import alterLogotypeBlack from "../public/alter-logotype-black.svg";
import profile from "../public/icons/profile.svg";
import profileWhite from "../public/icons/profile-white.svg";

interface HamburgerProps {
  open: boolean;
  onClick: () => void;
  dark: boolean;
}

const Hamburger = ({ open, onClick, dark }: HamburgerProps) => {
  return (
    <div
      className="relative flex h-[20px] w-[20px] cursor-pointer flex-col justify-between shrink-0 mr-3 md:hidden"
      onClick={onClick}
    >
      <motion.div
        animate={{
          opacity: open ? 0 : 1,
        }}
        className={`h-[2.5px] w-1/2 rounded-full ${
          dark ? "bg-black" : "bg-white"
        }`}
      />
      <motion.div
        animate={{
          opacity: open ? 0 : 1,
        }}
        className={`h-[2.5px] w-full rounded-full ${
          dark ? "bg-black" : "bg-white"
        }`}
      />
      <motion.div
        animate={{
          opacity: open ? 0 : 1,
        }}
        className={`h-[2.5px] w-full rounded-full ${
          dark ? "bg-black" : "bg-white"
        }`}
      />

      <motion.div
        animate={{
          rotate: open ? 45 : 0,
          opacity: open ? 1 : 0,
          x: "-50%",
          y: "-50%",
        }}
        className={clsx(
          "absolute top-1/2 left-1/2 h-[2.5px] w-[130%] -translate-y-1/2 -translate-x-1/2 rounded-full",
          dark ? "bg-black" : "bg-white"
        )}
      />
      <motion.div
        animate={{
          rotate: open ? -45 : 0,
          opacity: open ? 1 : 0,
          x: "-50%",
          y: "-50%",
        }}
        className={clsx(
          "absolute top-1/2 left-1/2 h-[2.5px] w-[130%] -translate-y-1/2 -translate-x-1/2 rounded-full",
          dark ? "bg-black" : "bg-white"
        )}
      />
    </div>
  );
};

const FETCH_CART = gql`
  ${CART}
  query Cart($cartId: ID!) {
    cart(id: $cartId) {
      ...CART
    }
  }
`;

interface NavBarProps {
  dark: boolean;
  hideBlurAtTop: boolean;
}

const NavBar = ({ dark, hideBlurAtTop }: NavBarProps) => {
  const { state } = useShopContext();
  const [scrolledDown, setScrolledDown] = useState(false);
  const [open, setOpen] = useState(false);
  const router = useRouter();

  const isSwabShop = router.pathname.includes("/shop/swab-kit");
  const isAccount = router.pathname.includes("/account");
  const shouldBlur = hideBlurAtTop ? scrolledDown : true;

  const { data } = useQuery(FETCH_CART, {
    variables: {
      cartId: state?.cart?.id,
    },
    context: {
      clientName: "shopify",
    },
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;

      const isScrolledDown = scrollPosition > window.innerHeight / 2;
      setScrolledDown(isScrolledDown);
    };

    if (hideBlurAtTop) {
      window.addEventListener("scroll", handleScroll);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [router.pathname]);

  const handleSystemDropDownInteracted = (destinationPath: string) => {
    setOpen(false);
    systemDropDownInteractedAnalytics(
      window.location.hostname + destinationPath,
      document.title
    );
  };

  return (
    <>
      <header
        className={clsx(
          "absolute top-0 z-20 w-full p-2",
          "md:px-20 md:py-9",
          dark ? "text-black" : "text-white"
        )}
      >
        <div
          className={clsx(
            "flex w-full items-center justify-between gap-4 py-2 pr-2 pl-3 rounded-[32px]",
            "max-w-[1440px] md:rounded-[36px] w-full mx-auto pl-10 pr-5 py-[12px] transition-all",
            shouldBlur && "backdrop-blur-md"
          )}
        >
          <Link
            href="/"
            className={`relative h-[22px] ml-2 shrink-0 ${
              open ? "opacity-0" : ""
            }`}
            onClick={() => setOpen(false)}
          >
            <Image
              src={dark ? alterLogotypeBlack : alterLogotype}
              alt="Alter logotype"
              className="h-full w-auto"
            />
          </Link>

          <div className="hidden md:flex md:gap-8">
            <Link href="/why-alter">Why Alter</Link>
            <Link
              href="/learn/system"
              onClick={() => handleSystemDropDownInteracted("/learn/system")}
            >
              How It Works
            </Link>
            <Link href="/pricing">Pricing</Link>
          </div>

          <div className="flex items-center gap-4 md:gap-8">
            {!isSwabShop && (
              <button
                className="pointer-events-auto"
                onClick={() =>
                  router.push({
                    pathname: "/account",
                    query: { setup: false },
                  })
                }
              >
                <Image
                  width={34}
                  height={34}
                  src={dark ? profile : profileWhite}
                  alt="profile"
                />
              </button>
            )}
            {!isAccount &&
              router.pathname !== "/learn/swab-kit" &&
              router.pathname !== "/shop/swab-kit" && (
                <Link
                  href="/shop/swab-kit"
                  className={clsx(
                    dark ? "dna-btn-black" : "dna-btn",
                    "px-4 py-1 text-base md:py-2"
                  )}
                >
                  Start Here
                </Link>
              )}
            <Hamburger open={open} onClick={() => setOpen(!open)} dark={dark} />
          </div>
        </div>
      </header>
      <AnimatePresence>
        {open && (
          <motion.div
            animate={{
              opacity: 1,
            }}
            initial={{
              opacity: 0,
            }}
            exit={{
              opacity: 0,
            }}
            className="absolute top-0 left-0 z-10 flex h-full w-full flex-col gap-5 bg-black/20 px-6 pt-20 text-[36px] text-alter-bone backdrop-blur-lg"
          >
            <Link href="/why-alter" onClick={() => setOpen(false)}>
              Why Alter
            </Link>
            <Link
              onClick={() => handleSystemDropDownInteracted("/learn/system")}
              href="/learn/system"
            >
              How It Works
            </Link>
            <Link href="/pricing" className="" onClick={() => setOpen(false)}>
              Pricing
            </Link>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default NavBar;
