// context/SegmentContext.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
import { ID } from "@segment/analytics-next";
import { initializeSegment } from "@/lib/segment/segment";

type SegmentContextType = {
  segmentAnonymousId: ID | null;
  isLoading: boolean;
};

const SegmentContext = createContext<SegmentContextType>({
  segmentAnonymousId: null,
  isLoading: true,
});

export const SegmentProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [segmentAnonymousId, setSegmentAnonymousId] = useState<ID | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    initializeSegment()
      .then((anonymousId) => {
        setSegmentAnonymousId(anonymousId);
      })
      .catch((error) => {
        console.error("Failed to initialize Segment:", error);
        setSegmentAnonymousId(null);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <SegmentContext.Provider value={{ segmentAnonymousId, isLoading }}>
      {children}
    </SegmentContext.Provider>
  );
};

export const useSegment = (): SegmentContextType => {
  const context = useContext(SegmentContext);
  if (!context) {
    throw new Error("useSegment must be used within a SegmentProvider");
  }
  return context;
};
