// https://github.com/vercel/next.js/tree/canary/examples/with-apollo

import { useMemo } from "react";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  from,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import merge from "deepmerge";
import isEqual from "lodash/isEqual";
import { parseAuthJWT, getAuthAccessToken } from "../auth0/auth";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";

export const APOLLO_STATE_PROP_NAME = "__APOLLO_STATE__";

let apolloClient;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

export const authTokenMiddleware = setContext((_, { headers }) => {
  const accessToken = getAuthAccessToken();
  const jwtRole = parseAuthJWT("role");
  return {
    headers:
      !!accessToken && !!jwtRole
        ? {
            ...headers,
            Authorization: `Bearer ${accessToken}`,
            "x-hasura-role": `${jwtRole}`,
          }
        : {
            ...headers,
          },
  };
});

const rapptrLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_DATABASE_URL,
  credentials: "same-origin",
});

const shopifyRetryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error, operation) => {
      return !!error && operation.operationName === "cartDiscountCodesUpdate";
    },
  },
  delay: {
    initial: 300,
    max: 1000,
    jitter: false,
  },
});

const shopifyLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_SHOPIFY_STORE_DOMAIN,
  headers: {
    "X-Shopify-Storefront-Access-Token":
      process.env.NEXT_PUBLIC_SHOPIFY_STORE_FRONT_ACCESS_TOKEN,
  },
});

const spaceId = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID;
const environment = process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT;
const contentfulLink = new HttpLink({
  uri: `https://graphql.contentful.com/content/v1/spaces/${spaceId}${environment ? "/environments/" + environment : ""}`,
  headers: {
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN}`,
  },
});

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: ApolloLink.split(
      (operation) => operation.getContext().clientName === "contentful",
      from([errorLink, contentfulLink]), // <= apollo will send to this if clientName is "contentful"
      ApolloLink.split(
        (operation) => operation.getContext().clientName === "shopify",
        from([errorLink, shopifyRetryLink, shopifyLink]), // <= apollo will send to this if clientName is "shopify"
        from([authTokenMiddleware, errorLink, rapptrLink]) // <= otherwise will send to this
      )
    ),
    cache: new InMemoryCache({
      typePolicies: {
        Cart: {
          fields: {
            attributes: {
              merge(existing = [], incoming = []) {
                // Merge the existing and incoming arrays by combining them
                const merged = [...existing, ...incoming];
								const unique = merged.filter(
									(item, index, self) => self.findIndex(i => i.key === item.key) === index
								);
								return unique;
              },
            },
          },
        },
      },
    }),
  });
}

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
