import { AnalyticsBrowser, ID } from "@segment/analytics-next";
import * as snippet from "@segment/snippet";
import { vwoMiddleware } from "../vwo/middleware";

let segmentUserAuthenticatedAnalytics: AnalyticsBrowser | undefined;
let segmentUserUnauthenticatedAnalytics: AnalyticsBrowser | undefined;
let segmentAnonymousId!: ID;

export const initializeSegment = async (): Promise<ID> => {
  console.debug(`INITIALIZING_SEGMENT`);
  if (typeof window !== "undefined") {
    segmentUserAuthenticatedAnalytics = new AnalyticsBrowser();
    segmentUserUnauthenticatedAnalytics = new AnalyticsBrowser();

    try {
      await Promise.all([
        segmentUserAuthenticatedAnalytics.load({
          writeKey: process.env.NEXT_PUBLIC_SEGMENT_ANALYTICS_KEY as string,
        }),
        segmentUserUnauthenticatedAnalytics.load({
          writeKey: process.env
            .NEXT_PUBLIC_SEGMENT_UNAUTHENTICATED_ANALYTICS_KEY as string,
        }),
      ]);

      segmentUserAuthenticatedAnalytics.addSourceMiddleware(vwoMiddleware());
      segmentUserUnauthenticatedAnalytics?.addSourceMiddleware(vwoMiddleware());

      segmentAnonymousId = (
				await segmentUserUnauthenticatedAnalytics?.user()
      )?.anonymousId();

			// Hack because "@littledata/headless-shopify-sdk"; fetchClientIds() does not work (!window.analytics)
			window.localStorage.setItem("littledataIDs", JSON.stringify({'_segment-clientID': segmentAnonymousId}));

      return segmentAnonymousId;
    } catch (error) {
      console.error("ERROR_LOADING_SEGMENT:", error);
    }
  }
};
export {
  segmentUserAuthenticatedAnalytics,
  segmentUserUnauthenticatedAnalytics,
};

// // For unauthenticated events
// export const segmentUserUnauthenticatedAnalytics = ():
//   | AnalyticsSnippet
//   | undefined => {
//   // @ts-ignore
//   return typeof window !== "undefined" ? window?.analytics : undefined;
// };

// export const renderScript = () =>
//   snippet.min({
//     host: "cdn.segment.com",
//     apiKey: process.env.NEXT_PUBLIC_SEGMENT_UNAUTHENTICATED_ANALYTICS_KEY,
//     page: false,
//   });
